import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    value: [],
};

const authUnlockSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
         authUnlockHandler: (state,action) => {
             state.value = action.payload
         }
    }
});

export const {authUnlockHandler} = authUnlockSlice.actions;
export default authUnlockSlice.reducer;
import Home from 'components/Home'
import Layout from 'layouts/Layout'
import React from 'react'

const HomePage = () => {
  return (
    <Layout type="home">
        <Home />
    </Layout>
  )
}

export default HomePage

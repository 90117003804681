import React from 'react';
import {  BrowserRouter as Router } from 'react-router-dom';
import Routers from './routes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Router basename="">
        <Routers />
      </Router>
      <ToastContainer style={{width: "480px"}} />
    </>
  );
}

export default App;

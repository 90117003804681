import React from 'react';
import classes from '../assets/styles/ModalBox.module.css';
import { motion } from 'framer-motion';

const ModalBox = ({children,title,show,setShow,event}) => {
  return (
    <motion.div 
        onClick={() => setShow(false)}
        initial={{ opacity: 0, display: 'none' }}
        animate={{ opacity: show ? 1:0, display: show ? 'flex':'none' }}
        transition={{ duration: 0.3, ease: "linear" }}
        className={classes.backDrop}>
        <motion.div className={classes.card}
            onClick={e => e.stopPropagation()}
            initial={{ y: '-100%' }}
            animate={{ y: show ? '0%':'-100%'}}
            transition={{ duration: 0.3, ease: "linear" }}
        >
            <div className={classes.cardHeader}>
                {title}
            </div>
            <hr style={{borderColor: '#2b27381f', borderWidth: 0.5 }}/>
            <div className={classes.content}>
                {children}
            </div>
            <hr style={{borderColor: '#2b27381f', borderWidth: 0.5 }}/>
            <div className={classes.cardFooter}>
                <div className={classes.buttonGroup}>
                    <button onClick={() => setShow(false)}>Cancel</button>
                    <button onClick={event}>Save</button>
                </div>
            </div>
        </motion.div>
    </motion.div>
  )
}

export default ModalBox

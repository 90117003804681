import React, { useReducer, useRef, useState } from 'react';
import classes from 'assets/styles/Login.module.css';
import Input from 'utils/Input';
import logo from 'assets/images/logo/logo.png';
import { INITIAL_STATE, loginReducer } from 'reducers/loginReducer';
import { useNavigate } from 'react-router-dom';
import { LOGIN_API } from 'service/apis';
import { encodeAuth, errorToaster, LogoutHandle, successToaster } from 'utils/Helper';
import { postMethodService } from 'service';
import { createPortal } from 'react-dom';
import Loader from 'utils/Loader';
import { TiArrowBack } from 'react-icons/ti';

const Login = () => {
  const [state,dispatch] = useReducer(loginReducer, INITIAL_STATE);
  const [isLoading,setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toastId = useRef(null);

  const changeHandler = (e) => {
     dispatch({type: "CHANGE_INPUT", payload: {name: e.target.name, value: e.target.value}})
  };

  const loginHandler = async e => {
     e.preventDefault();
     if(state.username && state.password){
        setIsLoading(true);
        let res = await postMethodService(LOGIN_API,state);
        if(res){
            setIsLoading(false);
            if(res === "Unauthenticated"){
                return LogoutHandle(navigate);
            };
            if(res.status === "success"){
                successToaster("Success",toastId);
                let saveInfo = {
                  token: res?.data?.token,
                };
                const encoded = encodeAuth(saveInfo);
                localStorage.setItem("mac_auth", encoded);
                setTimeout(()=>{
                    navigate('/dashboard/home')
                },800);
            }else{
                return errorToaster(res,toastId)
            };
        };
     };
  };

  return (
    <>
      <div className={classes.loginContainer}>
          <div className={classes.back} onClick={() => navigate('/')}>
              <TiArrowBack color='var(--accent-color)' size={35} />
          </div>
          <div className={classes.loginCard}>
              <div className={classes.logo}>
                <img src={logo} alt='logo' />
              </div>
              <form onSubmit={loginHandler}>
                <Input name="username" type="text" placeholder="Username" onChange={changeHandler} />
                <Input name="password" type="password" placeholder="Password" onChange={changeHandler} />
                <button type='submit' className={classes.submitBtn}>Login</button>
              </form>
          </div> 
      </div>
      {
          createPortal(isLoading && <Loader />, document.getElementById('loading'))
      }
    </>
  )
}

export default Login

import React, { useEffect, useRef, useState } from 'react';
import classes from 'assets/styles/DashboardSetting.module.css';
import Sidebar from './Sidebar';
import { AiFillEdit } from 'react-icons/ai';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import Checkbox from 'utils/Checkbox';
import ModalBox from 'utils/ModalBox';
import { errorToaster, LogoutHandle, successToaster } from 'utils/Helper';
import { CONFIG_API, EDIT_CONFIG_API } from 'service/apis';
import { getMethodService, postMethodService } from 'service';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import Loader from 'utils/Loader';

const DashboardSetting = () => {
  const [closes,setCloses] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [data,setData] = useState({});
//   const [createBannerModal,setCreateBannerModal] = useState(false);
//   const [editBannerModal,setEditBannerModal] = useState(false);
  const [deleteBannerModal,setDeleteBannerModal] = useState(false);
  const [editCaptionModal,setEditCaptionModal] = useState(false);
  const [anno,setAnno] = useState("");
  const toasterId = useRef(null);
  const navigate = useNavigate();

  const fetchConfig = async () => {
        setIsLoading(true);
        const res = await getMethodService(CONFIG_API);
        if(res){
            setIsLoading(false);
            if(res?.status === 'success'){
                setData(res?.data);
            };
        };
  };

  useEffect(()=>{
    setAnno(data?.announcement_text);
    if(data?.two_d_close_days){
        if(typeof data?.two_d_close_days === 'string'){
            setCloses(JSON.parse(data?.two_d_close_days));
        }else{
            setCloses(data?.two_d_close_days);
        };
    };
    
  },[data]);

  useEffect(()=>{
    fetchConfig();
  },[]);

  const closeCheckBoxHandler = (id) => {
        setCloses(prev => {
            if(prev.includes(id)){
                return prev.filter(tid => tid !== id);
            }else{
                return [...prev, id];
            };
        });
  };

  const saveClosesHandler = async () => {
     setIsLoading(true);
     const data = {
         two_d_close_days: JSON.stringify(closes)
     };
     let res = await postMethodService(EDIT_CONFIG_API,data);
     if(res){
        setIsLoading(false);
        if(res === "Unauthenticated"){
            return LogoutHandle(navigate);
        };
        if(res.status === "success"){
            successToaster("Success",toasterId);
            fetchConfig();
        }else{
            return errorToaster(res,toasterId)
        };
     };
  };

  const editAnnoHandler = async () => {
      setIsLoading(true)
      const data = {
            announcement_text: anno
      };
      let res = await postMethodService(EDIT_CONFIG_API,data);
      if(res){
            setIsLoading(false);
            if(res === "Unauthenticated"){
                return LogoutHandle(navigate);
            };
            if(res.status === "success"){
                successToaster("Success",toasterId);
                fetchConfig();
                setEditCaptionModal(false);
            }else{
                return errorToaster(res,toasterId)
            };
       };
  };


  return (
    <>
        <div className={classes.homeContainer}>
            <Sidebar />
            {/* <div style={{marginBottom: '25px', position: 'relative', zIndex: 10}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                    <h1 className={classes.title}>
                        Banner Images
                    </h1>
                    <button className={classes.createButton} onClick={() => setCreateBannerModal(true)}>Create</button>
                </div>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src='https://img.freepik.com/premium-photo/jackpot-joy-celebrating-lottery-wins-with-excitement_622540-13953.jpg?semt=ais_hybrid' />
                                </div>
                            </td>
                            <td>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AiFillEdit style={{cursor: 'pointer'}} onClick={() => setEditBannerModal(true)} size={25} color='var(--accent-color)' />
                                    <RiDeleteBin5Fill onClick={() => setDeleteBannerModal(true)} size={23} color='tomato' style={{marginLeft: '10px',cursor: 'pointer'}} />
                                </div>
                            </td>
                        </tr>
                    </tbody> 
                </table>
            </div>
            <hr style={{borderColor: '#2b273880', position: 'relative', zIndex: 10 }}/> */}
            <div style={{ position: 'relative', zIndex: 10, marginBottom: '25px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginBottom: '15px'}}>
                    <h1 className={classes.title}>
                        Announcement
                    </h1>
                </div>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td style={{width: '80%'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '12px', textAlign: 'justify'}}>
                                {data?.announcement_text}
                                </div>
                            </td>
                            <td style={{width: '20%'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AiFillEdit onClick={() => setEditCaptionModal(true)} style={{cursor:'pointer'}} size={25} color='green' />
                                </div>
                            </td>
                        </tr>
                    </tbody> 
                </table>
            </div>
            <hr style={{borderColor: '#2b273880', position: 'relative', zIndex: 10 }}/>
            <div style={{ position: 'relative', zIndex: 10, marginTop: '25px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                    <h1 className={classes.title}>
                        2D Close ( Weekly )
                    </h1>
                    <button className={classes.createButton} onClick={saveClosesHandler}>Save</button>
                </div>
                <div className={classes.checkBoxGroup}>
                    <Checkbox id={0} label="Sunday" closes={closes} event={closeCheckBoxHandler} />
                    <Checkbox id={1} label="Monday" closes={closes} event={closeCheckBoxHandler} />
                    <Checkbox id={2} label="Tuesday" closes={closes} event={closeCheckBoxHandler} />
                    <Checkbox id={3} label="Wednesday" closes={closes} event={closeCheckBoxHandler} />
                    <Checkbox id={4} label="Thursday" closes={closes} event={closeCheckBoxHandler} />
                    <Checkbox id={5} label="Friday" closes={closes} event={closeCheckBoxHandler} />
                    <Checkbox id={6} label="Saturday" closes={closes} event={closeCheckBoxHandler} />
                </div>
            </div>

            {/* Create Banner */}
            {/* <ModalBox title="Create Banner" show={createBannerModal} setShow={setCreateBannerModal} event={saveHandler}>
                <input type='file' />
            </ModalBox> */}
            {/* Edit Banner */}
            {/* <ModalBox title="Edit Banner" show={editBannerModal} setShow={setEditBannerModal} event={saveHandler}>
                <input type='file' />
            </ModalBox> */}

            {/* Delete Banner */}
            <ModalBox title="Delete Banner?" show={deleteBannerModal} setShow={setDeleteBannerModal} event={''}>
                <p>Are you sure to delete?</p>
            </ModalBox>

            {/* Edit Caption */}
            <ModalBox title="Edit Announcement" show={editCaptionModal} setShow={setEditCaptionModal} event={editAnnoHandler}>
                <textarea rows={6} style={{
                    width: '100%',
                    resize: 'none',
                    border: '1px solid #ccc',
                    outline: 'none',
                    borderRadius: '5px',
                    padding: '10px',
                    color: '#2b2738'
                }}
                value={anno}
                onChange={e => setAnno(e.target.value)}
                ></textarea>
            </ModalBox>
        </div>
        {
            createPortal(isLoading && <Loader />, document.getElementById('loading'))
        }
    </>
  )
}

export default DashboardSetting

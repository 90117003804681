import PrivacyPolicy from 'components/PrivacyPolicy'
import Layout from 'layouts/Layout'
import React from 'react'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
        <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPolicyPage

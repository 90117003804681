import Login from 'components/auth/Login'
import Layout from 'layouts/Layout'
import React from 'react'

const LoginPage = () => {
  return (
    <Layout hideHeader={true}>
        <Login />
    </Layout>
  )
}

export default LoginPage

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
const initialState = {
    dateValue: moment().format('YYYY-MM-DD'),
};

const dateSlice = createSlice({
    name: 'date',
    initialState,
    reducers: {
         changeDateHandler: (state,action) => {
             state.dateValue = action.payload
         }
    }
});

export const {changeDateHandler} = dateSlice.actions;
export default dateSlice.reducer;
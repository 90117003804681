import React, { useEffect, useState } from 'react';
import classes from 'assets/styles/History.module.css';
import HistoryList from './HistoryList';
import { getMethodService } from 'service';
import { THREE_D_HISTORY_API, TWO_D_HISTORY_API } from 'service/apis';
import moment from 'moment';
import NotFound from 'utils/NotFound';
import { createPortal } from 'react-dom';
import Loader from 'utils/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { twodTimesValueHandler } from 'store/slices/twodTimesSlice';
import {io} from "socket.io-client";

const History = ({page}) => {
  const [isLoading,setIsLoading] = useState(true);
  const {dateValue} = useSelector(state => state.date);
  const [data,setData] = useState([]);
  const [filterData,setFilterData] = useState([]);
  const [result,setResult] = useState(null);
  const [lastDatas,setLastDatas] = useState([]);
  const {lotteryType} = useSelector(state => state.type);
  const {pathname} = useLocation();
  const dispatch = useDispatch();
 
  let threedParam = page === 'home'? '?pageSize=3': '?pageSize=50'; 

  const fetchThreedHistory = async () => {
      setIsLoading(true);
      const res = await getMethodService(`${THREE_D_HISTORY_API}${threedParam}`);
      if(res){
          setIsLoading(false);
          if(res?.status === 'success'){
            setData(res?.data);
          };
      };
  };

  const fetchTwodHistory = async () => {
    setIsLoading(true);
    let tempTimes = [];
    // ?date=${date}
    const res = await getMethodService(`${TWO_D_HISTORY_API}?date=${dateValue}`);
    if(res){
        setIsLoading(false);
        if(res?.status === 'success'){
          setData(res?.data);
          if(res?.data?.length > 0){
            res?.data?.map(el => {
              tempTimes.push(moment(el?.end).format("hh:mm A"));
            });
            dispatch(twodTimesValueHandler(tempTimes));
          }
        };
    }
};

  useEffect(()=>{
    if(lotteryType === "3D"){
      if(pathname === '/threed-history' || pathname === '/'){
        fetchThreedHistory();
      }else{
        fetchTwodHistory();
      };
    }else{
      fetchTwodHistory();
    };
  },[lotteryType,dateValue]);

  useEffect(()=>{
    let url = process.env.REACT_APP_DOMAIN
    const socket = io(url, {
        path: "/api/lottery/socket",
    });
    socket.connect();
    socket.on("confirm_two_d_lucky_number", res => {
      if(lotteryType === "2D"){
        setResult(res);
      };
    });
    socket.on("confirm_three_d_lucky_number", res => {
       if(lotteryType === "3D"){
          setResult(res);
       };
    });
    
    return () => {
        socket.disconnect();
    };
},[lotteryType]);

useEffect(()=>{
  if(lotteryType === "3D"){
    const filteredArray = data.filter(item => item.set !== null || item.value !== null || item.lucky_number !== null);
    setFilterData(filteredArray);
  }else{
     setFilterData(data);
  };
},[lotteryType,data]);

useEffect(()=>{
  if(result){
     let tempResults = [];
     if(filterData?.length > 0){
      if(lotteryType === "3D"){
        let copyArr = [...filterData];
        copyArr.unshift(result);
        setLastDatas(copyArr);
      }else{
        filterData?.map(reslt => {
            if (reslt?.date == result?.date){
              if (reslt?.batch === result?.batch){
                reslt = {
                  ...reslt,
                  set: result?.set || reslt?.set,
                  value: result?.value || reslt?.value,
                  lucky_number: result?.lucky_number || reslt?.lucky_number
                };
              };
            };
            tempResults.push(reslt);
        });
        setLastDatas(tempResults)
      };
     };
  }else{
    setLastDatas(filterData);
  }
},[lotteryType,result,filterData]);

// useEffect(()=>{
//    if(result){
//       let tempResults = [];
//       if(data?.length > 0){
//         data?.map(reslt => {
//           if (reslt?.date == result?.date){
//               if(lotteryType === "3D"){
//                 reslt = {
//                    ...reslt,
//                    set: result?.set || reslt?.set,
//                    value: result?.value || reslt?.value,
//                    lucky_number: result?.lucky_number || reslt?.lucky_number
//                 };
//               }else{
//                 if (reslt?.batch === result?.batch){
//                   reslt = {
//                     ...reslt,
//                     set: result?.set || reslt?.set,
//                     value: result?.value || reslt?.value,
//                     lucky_number: result?.lucky_number || reslt?.lucky_number
//                   };
//                 };
//               };
//             };
//             tempResults.push(reslt);
//         });
//         setLastDatas(tempResults)
//       };
//    }else{
//      setLastDatas(data);
//    }
// },[lotteryType,result,data]);

  return (
    <>
      <div className={classes.historyContainer}>
          {
            lastDatas?.length > 0 ?
              <ul className={classes.historyList}>
                {
                  lastDatas?.map(list => 
                    <HistoryList key={list?.id} lotteryType={lotteryType} list={list} pathname={pathname} />
                  )
                }
              </ul>
            :
            !isLoading && <NotFound text="No data!" />
          }
      </div>
      {
          createPortal(isLoading && <Loader />, document.getElementById('loading'))
      }
    </>
  )
}

export default History

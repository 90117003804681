import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    twodTimesValue: [],
};

const twodTimesSlice = createSlice({
    name: 'times',
    initialState,
    reducers: {
         twodTimesValueHandler: (state,action) => {
             state.twodTimesValue = action.payload
         }
    }
});

export const {twodTimesValueHandler} = twodTimesSlice.actions;
export default twodTimesSlice.reducer;
import DashboardSetting from 'components/dashboard/DashboardSetting'
import Layout from 'layouts/Layout'
import React from 'react'

const DashboardSettingPage = () => {
  return (
    <Layout hideHeader={true}>
        <DashboardSetting />
    </Layout>
  )
}

export default DashboardSettingPage

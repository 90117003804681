import React from 'react'

const NotFound = ({text}) => {
  return (
    <div style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px',
      color: 'var(--text-color)',
      backgroundColor: '#1c2035',
      borderRadius: '20px'
    }}>
        <p style={{fontSize: '25px', fontWeight: '500'}}>{text}</p>
    </div>
  )
}

export default NotFound

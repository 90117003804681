import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay,Pagination}  from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "../assets/styles/Banner.css";
import Marquee from 'react-fast-marquee';

const Banner = ({announcement}) => {

  return (
    <div>
        <div className="banner">
            <Swiper
                spaceBetween={10}
                pagination={false}
                modules={[Pagination, Autoplay]}
                autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                }}
                loop={true}
                speed={3000}
            >
                    <SwiperSlide>
                        <img className={"banner-img animate-fadein"} src={'https://img.freepik.com/premium-photo/jackpot-joy-celebrating-lottery-wins-with-excitement_622540-13953.jpg?semt=ais_hybrid'} alt="Banner" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img className={"banner-img animate-fadein"} src={'https://img.freepik.com/premium-photo/3d-rendering…-purple-background_463092-282.jpg?semt=ais_hybrid'} alt="Banner" />
                    </SwiperSlide>
            </Swiper>
            {
                announcement &&
                <div className="captionContainer">
                    <div className="banner-frame">
                        <Marquee
                            gradient={false}
                            speed={50}
                        >
                            {announcement} ... &nbsp;
                        </Marquee>
                    </div>
                </div>
            }
        </div>
      </div>
  )
}

export default Banner

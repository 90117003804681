import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    lotteryType: "2D",
};

const typeSlice = createSlice({
    name: 'lotteryType',
    initialState,
    reducers: {
         changeTypeHandler: (state,action) => {
             state.lotteryType = action.payload
         }
    }
});

export const {changeTypeHandler} = typeSlice.actions;
export default typeSlice.reducer;
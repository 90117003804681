import React, { useState } from 'react'
import classes from 'assets/styles/Input.module.css';
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';

const Input = ({name,type,placeholder,onChange}) => {
  const [isShow,setIsShow] = useState(false);
  const toggleEyeHandler = () => {
        setIsShow(prev => !prev);
  };
  return (
    <div className={classes.inputContainer}>
        <input name={name} autoComplete={name} type={type === 'password'? (isShow? 'type': type): type} style={{padding: type === "password" ? '13px 45px 13px 13px': '13px' }} placeholder={placeholder} className={classes.input} onChange={onChange}/>
        {
            type === "password" &&
            <div className={classes.icon} onClick={toggleEyeHandler}>
                {
                    isShow ?
                    <RiEyeLine size={25} color='var(--accent-color)' />:
                    <RiEyeCloseLine size={25} color='var(--accent-color)' />
                }
            </div>
        }
    </div>
  )
}

export default Input

import React from 'react'
import { Navigate } from 'react-router-dom';

const ProtectRoute = ({children}) => {
    const getAuth = localStorage.getItem('mac_auth');
    if(getAuth){
        return children;
    }else{
        return <Navigate to='/auth/login' />
    };
}

export default ProtectRoute

import * as React from "react";
import { motion } from "framer-motion";
import classes from '../../assets/styles/Sidebar.module.css';
import { useNavigate } from "react-router-dom";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = ({ label,icon,path,toggle }) => {
  const navigate = useNavigate();
  return (
    <motion.li
      variants={variants}
      // whileHover={{ scale: 1.1 }}
      // whileTap={{ scale: 0.95 }}
      className={classes.list}
      onClick={() => {toggle(); setTimeout(()=> navigate(path),900)}}
    >
      <div className={`${classes.listFlex} ${classes.active}`}>
          {icon}
          <div className={classes.label}>
              {label}
          </div>
      </div>
    </motion.li>
  );
};

import { useState, useEffect, useRef } from 'react';
import classes from 'assets/styles/Tabs.module.css';
import { useDispatch } from 'react-redux';
import { changeTypeHandler } from 'store/slices/typeSlice';

const Tabs = ({ allTabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const tabsRef = useRef([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentTab = tabsRef.current[activeTabIndex];
    setTabUnderlineLeft(currentTab.offsetLeft);
    setTabUnderlineWidth(currentTab.offsetWidth);
    dispatch(changeTypeHandler(currentTab.innerHTML));
  }, [activeTabIndex, allTabs]);

  return (
    <div className={classes.tabsContainer}>
      <span
        className={classes.tabUnderline}
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      >
        <span className={classes.tabUnderlineInner} />
      </span>
      {allTabs.map((tab, index) => {
        const isActive = activeTabIndex === index;

        return (
          <button
            key={index}
            ref={(el) => (tabsRef.current[index] = el)}
            className={`${classes.tabButton} ${isActive ? classes.activeTab : classes.inactiveTab}`}
            style={{color: isActive? '#FFF':'#484330'}}
            onClick={() => setActiveTabIndex(index)}
          >
            {tab.name}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;

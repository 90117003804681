import History from 'components/History'
import Layout from 'layouts/Layout'
import React from 'react'

const HistoryPage = () => {
  return (
    <Layout>
        <History />
    </Layout>
  )
}

export default HistoryPage

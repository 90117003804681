import CryptoJS from "crypto-js";
import moment from "moment";
import { Navigate } from "react-router-dom";
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const encodeAuth = (auth) => {
    const deText = CryptoJS.AES.encrypt(
      JSON.stringify(auth),
      process.env.REACT_APP_SECRET_KEY
    ).toString();
    return deText;
};
  
export const decodeAuth = (auth) => {
    if (auth) {
      try {
        const bytes = CryptoJS.AES.decrypt(
          auth,
          process.env.REACT_APP_SECRET_KEY
        );
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
      } catch (err) {
        console.log(err);
        const getFromStore = localStorage.getItem("mac_auth");
        if (getFromStore) {
          localStorage.removeItem("mac_auth");
          return <Navigate to="/auth/login" />;
        } else {
          return <Navigate to="/auth/login" />;
        }
      }
    }
};
  
export const LogoutHandle = (navigate) => {
    localStorage.removeItem("mac_auth");
    navigate("/auth/login");
};

export const errorToaster = (message,toastId) => {
  if(toastId){
      if(!toast.isActive(toastId.current)){
          toastId.current = toast.error(message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Slide,
              hideProgressBar: true,
              // theme: 'colored',
          });
      };
  }else{
      toast.error(message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          hideProgressBar: true,
          // theme: 'colored'
      });
  }
};

export const successToaster = (message,toastId) => {
  if(toastId){
      if(!toast.isActive(toastId.current)){
          toast.current = toast.success(message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Slide,
              hideProgressBar: true,
              // theme: 'colored',
          });
      };
  }else{
      toast.success(message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          hideProgressBar: true,
          // theme: 'colored',
      });
  }
  
};

export const generatePermutations = input => {
  let result = [];
  const backtrack = (current, remaining) => {
    if (remaining.length === 0) {
      result.push(current);
      return;
    };
    for (let i = 0; i < remaining.length; i++) {
        let next = current + remaining[i];
        let rest = remaining.slice(0, i) + remaining.slice(i + 1);
        backtrack(next, rest);
    };
  };
  
  input && backtrack('', input);
  result.shift();
  return result.join(' , ');
}

let domain = process.env.REACT_APP_DOMAIN;




// AUTH APIS
export const LOGIN_API = `${domain}api/lottery/auth/login`;
export const CONFIG_API = `${domain}api/lottery/config`;
export const EDIT_CONFIG_API = `${domain}api/lottery/config/update`;




// THREE_D APIS
export const THREE_D_HISTORY_API = `${domain}api/lottery/macao/three_d`;

// TWO_D APIS
export const TWO_D_HISTORY_API = `${domain}api/lottery/macao/two_d`;


// MULTI TYPE APIS
export const RESULT_API = type => `${domain}api/lottery/macao/setting/${type}`;
export const EDIT_RESULT_API = id => `${domain}api/lottery/macao/update/${id}`;
import React, { useRef } from 'react';
import classes from '../../assets/styles/Sidebar.module.css';
import { useNavigate } from 'react-router-dom';
import { useCycle,motion } from 'framer-motion';
import { useDimensions } from 'hooks/useDimensions';
import { Navigation } from './Navigation';
import { MenuToggle } from './MenuToggle';
import logo from '../../assets/images/logo/logo.png';

const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 35px 32px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(25px at 35px 32px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };
  

  const variants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    }
  };

const Sidebar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const navigate = useNavigate();
  return (
    <motion.aside 
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className={classes.sidebar}
    >
        <motion.div className={classes.sidebarBackground} variants={sidebar} />
        <Navigation toggle={() => toggleOpen()} />
        <motion.div variants={variants} style={{backgroundColor: '#DDD', height: '65px', width: '260px', zIndex: 20, position: 'relative'}} />
        <MenuToggle toggle={() => toggleOpen()} />
        <motion.div variants={variants} style={{
                width: '45px',
                cursor: 'pointer',
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 20
            }} onClick={() => navigate('/')}>
            <img src={logo} style={{
                width: '100%',
            }} />
        </motion.div>
        {/* <header className={classes.sidebarHeader}>
            <div className={classes.headerFlex}>
                <div style={{
                        width: '40px',
                        cursor: 'pointer'
                    }} onClick={() => navigate('/')}>
                    <img src={logo} style={{
                        width: '100%',
                    }} />
                </div>
                <div style={{cursor: 'pointer'}} onClick={() => {}}>
                    <LiaTimesSolid color='#555' size={25} />
                </div>
            </div>
        </header> */}
    </motion.aside>
  )
}

export default Sidebar

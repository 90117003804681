import DashboardHome from 'components/dashboard/DashboardHome'
import Layout from 'layouts/Layout'
import React from 'react'

const DashboardHomePage = () => {
  return (
    <Layout hideHeader={true}>
        <DashboardHome />
    </Layout>
  )
}

export default DashboardHomePage

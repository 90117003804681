import React from 'react';
import classes from 'assets/styles/Layout.module.css';
import Header from './Header';
import PageTransition from './PageTransition';

const Layout = ({children,type,hideHeader}) => {
  return (
    <>
        <section style={{padding: hideHeader? '0': '70px 10px 10px 10px'}} className={classes.container}>
            {
                !hideHeader && <Header type={type} />
            }
            <PageTransition>
              <div className={classes.content}>
                {
                  children
                }
              </div>
          </PageTransition>
        </section>
    </>
  )
}

export default Layout

import React from 'react';
import closeImage from '../assets/images/others/close.png';

const CloseLottery = () => {
    return (
        <div style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          color: 'var(--text-color)',
          backgroundColor: '#1c2035',
          borderRadius: '20px'
        }}>
            <img src={closeImage} width="280px" />
        </div>
    )
}

export default CloseLottery

import React from 'react';
import classes from '../assets/styles/Checkbox.module.css'

const Checkbox = ({id,label,event,closes}) => {

  return (
    <div style={{marginBottom: '7px'}}>
        <input checked={closes.includes(id)} className={classes['inp-cbx']} id={`cbx-${id}`} type="checkbox" style={{display: 'none'}} onChange={(e) => event(id)}/>
        <label className={classes.cbx} htmlFor={`cbx-${id}`}><span>
        <svg width="12px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </svg></span><span>{label}</span></label>
    </div>
  )
}

export default Checkbox

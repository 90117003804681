import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import classes from '../../assets/styles/Sidebar.module.css';
import { AiFillHome } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = ({toggle}) => (
  <motion.ul variants={variants} className={classes.listContainer}>
    <MenuItem toggle={toggle} label="Home" icon={<AiFillHome color="#2b2738" size='23px' />} path="/dashboard/home" />
    <MenuItem toggle={toggle} label="Setting" icon={<IoMdSettings color="#2b2738" size='23px' />} path="/dashboard/setting" />
  </motion.ul>
);

import React, { useEffect, useState } from 'react';
import classes from 'assets/styles/Home.module.css';
import Live from './Live';

import { useDispatch, useSelector } from 'react-redux';
import { authUnlockHandler } from 'store/slices/authUnlockSlice';
import { useNavigate } from 'react-router-dom';
import Banner from './Banner';
import { getMethodService } from 'service';
import { CONFIG_API } from 'service/apis';
import Loader from 'utils/Loader';
import { createPortal } from 'react-dom';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);
  const [data,setData] = useState({});
  const {value} = useSelector(state => state.authUnlock);

  const checkFirstThreeEqual = (arr) => {
    if (arr.length < 3) {
      return false;
    };
    return arr[0] === 2 && arr[1] === 2 && arr[2] === 2;
  }
  useEffect(() => {
    let timeoutId;
    if (value.length > 0) {
      if (value.length === 6) {
        let navigateStatus = checkFirstThreeEqual(value);
        if(navigateStatus){
          navigate('/auth/login');
        };
      };
  
      timeoutId = setTimeout(() => {
        dispatch(authUnlockHandler([]));
      }, 1000 * 10);
    };
  
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(()=>{
    return () => dispatch(authUnlockHandler([]));
  },[]);

  const fetchConfig = async () => {
      setIsLoading(true);
      const res = await getMethodService(CONFIG_API);
      if(res){
          setIsLoading(false);
          if(res?.status === 'success'){
              setData(res?.data);
          };
      };
  };

  useEffect(()=>{
    fetchConfig();
  },[]);

  return (
    <>
        <div className={classes.homeContainer}>
            <Banner announcement= {data?.announcement_text}/>
            <Live two_d_close_days={data?.two_d_close_days && JSON.parse(data?.two_d_close_days)}/>
        </div>
        {
          createPortal(isLoading && <Loader />, document.getElementById('loading'))
        }
    </>
  )
}

export default Home
